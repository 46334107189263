/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';

const StyledCookiePopup = styled.div`
  position: fixed;
  width: 100%;
  height: auto;
  bottom: 0;
  background-color: ${({ theme }) => theme.colors.mainText};
  border: 2px solid ${({ theme }) => theme.colors.main};
  padding: 2rem;
  right: 0;
  display: flex;
  flex-direction: column;
  font-size: 1.2rem;
  z-index: 23;
  @media ${({ theme }) => theme.breakpoints.tablet} {
    font-size: 1.3rem;
  }
  @media ${({ theme }) => theme.breakpoints.pc} {
    width: 45rem;
    font-size: 1.5rem;
    right: 3rem;
    bottom: 3rem;
  }
`;

const StyledButton = styled.button`
  color: ${({ theme }) => theme.colors.main};
  margin: 1rem 0;
  padding: 1.5rem;
  background-color: ${({ theme }) => theme.colors.mainText};
  border: 1px solid ${({ theme }) => theme.colors.main};
  :hover,
  :active {
    background-color: ${({ theme }) => theme.colors.main};
    color: ${({ theme }) => theme.colors.mainText};
  }
`;

const CookiePopup = () => {
  const [cookieConsent, setCookieConsent] = useState(true);
  useEffect(() => {
    if (window?.localStorage.getItem('consent') === null) {
      setCookieConsent(false);
    }
    window?.localStorage.setItem('consent', `${cookieConsent}`);
  }, [cookieConsent]);

  return (
    <>
      {!cookieConsent && (
        <StyledCookiePopup>
          <span>
            CVLH.IO использует файлы Cookies. Они нужны, чтобы с сайтом было удобнее работать. В cookies содержатся
            данные о прошлых посещениях сайта. Если вы не хотите, чтобы эти данные использовались, отключите cookies в
            настройках браузера.
          </span>
          <StyledButton role="button" onClick={() => setCookieConsent(!cookieConsent)}>
            ПОНИМАЮ
          </StyledButton>
        </StyledCookiePopup>
      )}
    </>
  );
};

export default CookiePopup;
