/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-expressions */
import siteConfig from '@root/siteConfig';
import PropTypes from 'prop-types';
import React, { createContext, useState, useContext, useCallback, useRef } from 'react';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { navigate } from 'gatsby';
import { useTheme } from 'emotion-theming';
import { css, Global } from '@emotion/core';
import { useLogin } from './login';

const { siteTitle = 'CVLH' } = siteConfig || {};

const defaultValues = {
  siteTitle,
  isSearchOpen: false,
  isMenuOpen: false,
  menuRef: null,
  scrollbarPosition: 0,
  isDark: false,
  setScrollbarPosition: () => {},
  toggleSearchOpen: () => {},
  toggleMenuOpen: () => {},
  resetNavigation: () => {},
  toggleDark: () => {},
};

const options = {
  reserveScrollBarGap: true,
};

export const AppContext = createContext(defaultValues);

const AppProvider = ({ children, isDark, setIsDark }) => {
  const theme = useTheme();
  const [isSearchOpen, setSearchOpen] = useState(false);
  const [isMenuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef();

  const toggleDark = useCallback(() => {
    setIsDark(!isDark);
    window?.localStorage?.setItem('theme', `${!isDark}`);
  }, [isDark]);
  const toggleSearchOpen = useCallback(
    ref => {
      if (ref) {
        if (!isSearchOpen) {
          disableBodyScroll(ref, options);
        } else {
          enableBodyScroll(ref);
        }
      }
      setSearchOpen(state => !state);
    },
    [isSearchOpen]
  );
  const toggleMenuOpen = useCallback(
    to => {
      if (menuRef?.current) {
        if (!isMenuOpen) {
          disableBodyScroll(menuRef.current, options);
        } else {
          enableBodyScroll(menuRef.current);
        }
      }
      if (to) {
        navigate(to);
      }
      setMenuOpen(state => !state);
    },
    [isMenuOpen]
  );
  const resetNavigation = useCallback(() => {
    setSearchOpen(false);
    setMenuOpen(false);
    clearAllBodyScrollLocks();
  }, []);

  return (
    <AppContext.Provider
      value={{
        ...defaultValues,
        isSearchOpen,
        isMenuOpen,
        toggleMenuOpen,
        toggleSearchOpen,
        resetNavigation,
        menuRef,
        isDark,
        toggleDark,
      }}
    >
      <Global
        styles={css`
          html {
            color: ${theme.colors.blackText};
            background-color: ${theme.colors.mainText};
          }
          html {
            a {
              color: ${theme.colors.blackText};
              :hover,
              :focus {
                color: ${theme.colors.whiteGray};
                text-decoration: none;
              }
            }
          }
        `}
      />
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => {
  const store = useContext(AppContext);

  if (!store) {
    throw new Error('useAppContext must be used within a StoreProvider.');
  }

  return store;
};

AppProvider.propTypes = {
  children: PropTypes.node.isRequired,
  setIsDark: PropTypes.func.isRequired,
  isDark: PropTypes.bool.isRequired,
};
export { useLogin };
export default AppProvider;
