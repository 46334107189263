import PropTypes from 'prop-types';
import React, { createContext, useState, useContext, useCallback } from 'react';

const defaultValues = {
  isLogin: true,
  isLoading: false,
  needPassword: false,
  errorMessage: null,
  currentTab: 'login',
  mergeObject: null,
  toggleLoading: () => {},
  setCurrentTab: () => {},
  toggleLogin: () => {},
  togglePassword: () => {},
  setError: () => {},
  setLogin: () => {},
  clearError: () => {},
  setCredentials: () => {},
  resetForm: () => {},
};

export const LoginContext = createContext(defaultValues);

const LoginProvider = ({ children }) => {
  const [isLogin, setIsLogin] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [needPassword, setNeedPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [mergeObject, setMergeObject] = useState(null);
  const [currentTab, setTab] = useState('login');

  const resetForm = useCallback(() => {
    setIsLogin(true);
    setIsLoading(false);
    setNeedPassword(false);
    setErrorMessage(null);
    setMergeObject(null);
    setTab('login');
  }, []);
  const toggleLogin = useCallback(() => {
    setIsLogin(state => !state);
  }, [isLogin]);
  const setCurrentTab = useCallback(tab => {
    setTab(tab);
  }, []);
  const setLogin = useCallback(login => {
    setIsLogin(login);
  }, []);
  const setCredentials = useCallback(({ credential, email }) => {
    setMergeObject({ credential, email });
  }, []);
  const toggleLoading = useCallback(() => {
    setIsLoading(state => !state);
  }, [isLoading]);
  const togglePassword = useCallback(() => {
    setNeedPassword(state => !state);
  }, [needPassword]);
  const setError = useCallback(
    error => {
      const errorMsg = error.message || error.error || 'something bad happened';
      switch (errorMsg) {
        case 'popup_closed_by_user':
          setErrorMessage('Ошибка: Пользователь закрыл окно Google');
          toggleLoading();
          break;

        default:
          setErrorMessage(errorMsg);
          toggleLoading();
          break;
      }
    },
    [errorMessage]
  );
  const clearError = useCallback(() => {
    setErrorMessage(null);
  }, []);

  return (
    <LoginContext.Provider
      value={{
        ...defaultValues,
        isLogin,
        isLoading,
        needPassword,
        errorMessage,
        toggleLogin,
        toggleLoading,
        togglePassword,
        setError,
        clearError,
        setLogin,
        mergeObject,
        setCredentials,
        setCurrentTab,
        currentTab,
        resetForm,
      }}
    >
      {children}
    </LoginContext.Provider>
  );
};

export const useLogin = () => {
  const store = useContext(LoginContext);

  if (!store) {
    throw new Error('useAppContext must be used within a StoreProvider.');
  }

  return store;
};

LoginProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LoginProvider;
