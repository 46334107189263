module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Cybervalhalla медиа","short_name":"CVLH","start_url":"/","background_color":"#172530","theme_color":"#172530","display":"minimal-ui","icon":"src/images/logos.svg","description":"Сайт Cybervalhalla","lang":"ru-RU","orientation":"any","prefer_related_applications":false,"cache_busting_mode":"none"},
    },{
      plugin: require('../node_modules/gatsby-plugin-yandex-metrika/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":57714403,"webvisor":true,"trackHash":true,"useCDN":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
