// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-podcasts-jsx": () => import("./../src/templates/podcasts.jsx" /* webpackChunkName: "component---src-templates-podcasts-jsx" */),
  "component---src-templates-post-jsx": () => import("./../src/templates/post.jsx" /* webpackChunkName: "component---src-templates-post-jsx" */),
  "component---src-templates-authors-jsx": () => import("./../src/templates/authors.jsx" /* webpackChunkName: "component---src-templates-authors-jsx" */),
  "component---src-templates-index-jsx": () => import("./../src/templates/index.jsx" /* webpackChunkName: "component---src-templates-index-jsx" */),
  "component---src-templates-articles-jsx": () => import("./../src/templates/articles.jsx" /* webpackChunkName: "component---src-templates-articles-jsx" */),
  "component---src-pages-404-jsx": () => import("./../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */)
}

