import React from 'react';
import PropTypes from 'prop-types';
import ThemeProvider from '@/theming';
import CookiePopup from '@/components/CookiePopup';

const Layout = ({ children }) => {
  return (
    <ThemeProvider>
      {children}
      <CookiePopup />
    </ThemeProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default React.memo(Layout);
