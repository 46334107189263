/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-expressions */
import { ThemeProvider } from 'emotion-theming';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { css, Global } from '@emotion/core';
import theme from './theme';
import AppProvider from '@/context';
// import NavigationProvider from '@/hooks/context';

const globalStyle = css`
  html {
    text-size-adjust: 100%;
    -webkit-box-pack: start;
    -webkit-font-smoothing: antialiased;
    font-size: 62.5%;
    font-weight: 400;
    word-spacing: 0.1rem;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
    font-family: 'Montserrat', sans-serif;
    height: 100%;
    width: 100%;
    body {
      height: 100%;
      width: 100%;
      transition: color background-color 0.25s ease-in-out;
      #___gatsby {
        width: 100%;
        height: 100%;
        > div {
          width: 100%;
          height: 100%;
        }
      }
    }
    .scrollbar-measure {
      width: 100px;
      height: 100px;
      overflow: scroll;
      position: absolute;
      top: -9999px;
    }
  }

  *,
  *:before,
  *:after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
`;

const isTrueSet = myValue => myValue === 'true';
const isBrowser = typeof window !== `undefined`;

const IndexPage = ({ children }) => {
  const [isDark, setIsDark] = useState(false);

  const currentTheme = theme(isDark);

  useEffect(() => {
    if (isBrowser) {
      const localTheme = window?.localStorage?.getItem('theme');
      const preferDark = window?.matchMedia('(prefers-color-scheme: dark)')?.matches;
      if (localTheme) {
        setIsDark(isTrueSet(localTheme));
      } else if (preferDark) {
        setIsDark(true);
        window?.localStorage?.setItem('theme', 'true');
      } else {
        setIsDark(false);
        window?.localStorage?.setItem('theme', `false`);
      }
    }
  }, []);

  return (
    <ThemeProvider theme={currentTheme} children={children}>
      <Global styles={globalStyle} />
      <AppProvider isDark={isDark} setIsDark={setIsDark}>
        {children}
      </AppProvider>
    </ThemeProvider>
  );
};

IndexPage.propTypes = {
  children: PropTypes.node,
};

IndexPage.defaultProps = {
  children: null,
};

export default IndexPage;
