module.exports = {
  siteTitle: 'Cybervalhalla',
  siteTitleAlt: 'Cybervalhalla',
  publisher: 'Cybervalhalla',
  siteDescription:
    'Электронное издание про электронные развлечения. Новости про игры, написанные не как новости про игры',
  siteUrl: 'https://cvlh.io',
  postsPerHomePage: 7,
  postsPerPage: 6,
  author: 'TheFedaikin',
  // authorUrl: 'https://gcn.netlify.com/about/', // URL used for author and publisher schema, can be a social profile or other personal site
  // userTwitter: '@twitter', // Change for Twitter Cards
  // shortTitle: 'GCN App', // Used for App manifest e.g. Mobile Home Screen
  // shareImage: '/logos/share.jpg', // Open Graph Default Share Image. 1200x1200 is recommended
  // shareImageWidth: 900, // Change to the width of your default share image
  // shareImageHeight: 600, // Change to the height of your default share image
  // siteLogo: '/logos/logo-512.png', // Logo used for SEO, RSS, and App manifest
  // backgroundColor: '#e9e9e9', // Used for Offline Manifest
  // themeColor: '#121212', // Used for Offline Manifest
  // copyright: 'Copyright © 2019 TheFedaikin', // Copyright string for the RSS feed
};
