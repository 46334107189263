module.exports = isDark => ({
  colors: !isDark
    ? {
        blackText: '#121212', // Black
        grayText: '#222222',
        whiteGray: '#aaaaaa',
        secondaryGray: '#626262',
        mainText: '#fafafa', // Medium Gray
        secondaryText: '#e9e9e9',
        highlight: '#5b8bf7', // Light Blue
        mask: 'rgba(0, 0, 0, 0.45)',
        main: '#172530',
        mainOpacity: 'rgba(23, 37, 48, 0.8)',
        secondOpacity: 'rgba(18, 18, 18, 0.07)',
        alwaysWhite: '#fafafa',
        alwaysGrey: '#e9e9e9',
      }
    : {
        blackText: '#fafafa', // Black
        grayText: '#e9e9e9',
        whiteGray: '#aaaaaa',
        secondaryGray: '#626262',
        mainText: '#172530', // Medium Gray
        secondaryText: '#1f3241',
        highlight: '#5b8bf7', // Light Blue
        mask: 'rgba(0, 0, 0, 0.45)',
        main: '#fafafa',
        mainOpacity: 'rgba(250, 250, 250, 0.8)',
        secondOpacity: 'rgba(18, 18, 18, 0.07)',
        alwaysWhite: '#fafafa',
        alwaysGrey: '#e9e9e9',
      },
  sizes: {
    maxWidth: '1050px',
    maxWidthCentered: '650px',
  },
  breakpoints: {
    touch: 'screen and (max-width: 767px)',
    pc: 'screen and (min-width: 768px)',
    bigscreen: 'screen and (min-width: 992px)',
    prebigscreen: 'screen and (max-width: 991px)',
    mobile: 'screen and (max-width: 575px)',
    nonmobile: 'screen and (min-width: 576px)',
    tablet: 'screen and (min-width: 576px) and (max-width: 767px)',
    desktop: 'screen and (min-width: 768px) and (max-width: 991px)',
    widescreen: 'screen and (min-width: 992px) and (max-width: 1199px)',
    beyonddesktop: 'screen and (min-width:992px)',
    hd: 'screen and (min-width: 1200px)',
    beyondmaxwidth: 'screen and (min-width: 1050px)',
    beforemaxwidth: 'screen and (max-width: 1049px)',
  },
});
